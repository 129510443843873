import React from 'react';
import ReactLoading from 'react-loading';

import styles from './loading.module.scss';
import clsx from 'clsx';

export const Loading = ({
  floating=false,
  small,
}: {
  floating?: boolean;
  small?: boolean;
}) => {
  return <div className={clsx(styles.loadingWrapper, floating && styles.floating, small && styles.small)}>
    <ReactLoading
      type='cubes'
      color='lightgreen'
      height='64px'
      width='128px'
      className={clsx(styles.loading, small && styles.small)}
    />
  </div>
}