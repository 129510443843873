import { useEffect, useState } from 'react';
import { Document, DocumentPatch, DocumentStatus, useDocumentsApi } from '../../io/documents-api';
import { useAsync, useErrors, useHttp, useMapping } from '../../hooks';
import { Industry } from '../../io/industries-api';
import { Panel } from '../../ui/layout/Panel';
import { Text, Image } from '../../ui';
import { FileCard } from '../cards/file-card';
import { TextInput } from '../../ui/input/TextInput';
import { IndustriesDropdown } from '../industries/industries-dropdown';
import { SkillSelection } from '../skills/skill-selection';
import { ReferencesDropdown } from '../references/references-dropdown';
import { Button } from '../../ui/interactive/button';
import deepEqual from 'deep-equal';
import useEvents from '../../hooks/use-events';
import { Loading } from '../../ui/control/loading';
import { Reference, VerifiedReference } from '../../io/references-api';

const DocumentPreview = ({
  file,
  documentId,
}: {
  file?: File;
  documentId: string;
}) => {
  const image = useMapping(() => {
    if (file) return <FileCard file={file} />
    return <Image objectFit='contain' src={`/api/v1/documents/${documentId}/file`} />
  }, [ file, documentId ]);

  return <div style={{ width: '100%', height: '256px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    {image}
  </div>
}

const documentToPatch = (doc: Document): DocumentPatch => {
  return {
    description: doc.description,
    industryTag: doc.industryTag,
    skills: doc.skills,
    referenceId: doc.referenceId,
  }
}

export const EditDocumentCard = ({
  document,
}: {
  document: Document;
}) => {
  const documentsApi = useDocumentsApi();
  const events = useEvents();
  const [ patch, setPatch ] = useState<Partial<DocumentPatch>>(() => documentToPatch(document));
  const [ file, setFile ] = useState<File | null>(null);
  const http = useHttp();
  const errors = useErrors();

  const hasChanges = useMapping(() => {
    if (file !== null) return true;

    return !deepEqual(
      Object.keys(patch).map(k => patch[k]),
      Object.keys(patch).map(k => document[k]),
    );
  }, [ file, document, patch ]);

  const deleteFn = useAsync(() => documentsApi.delete(document.id), [ document.id ], {
    defer: true,
    default: null,
  });

  const submitFn = useAsync(async () => {
    // if (navigator.canShare()) {
    //   await navigator.share({
    //     url: 'localhost',
    //   }).catch(e => {
    //     console.log(e);
    //   });
    //   return document;
    // }

    return await documentsApi.submit(document.id);
  }, [ document.id ], {
    defer: true,
    default: null,
  });

  const saveFn = useAsync(async () => {
    if (file) {
      await documentsApi.uploadFile(document.id, file);
    }
    const doc = await documentsApi.update(document.id, patch);
    setFile(null);
    setPatch(() => documentToPatch(doc));
  }, [ file, document.id, patch  ], {
    defer: true,
    default: null,
  });

  const industrySelected = (industry: Industry) => {
    setPatch(patch => {
      if (industry.tag === patch.industryTag) return patch;
      return {
        ...patch,
        industryTag: industry.tag,
        skills: [],
      };
    });
  }

  const onSkillsSelected = (skills: string[]) => {
    setPatch(patch => ({ ...patch, skills }));
  }

  const onReferenceSelected = (reference: Reference) => {
    setPatch(patch => ({ ...patch, referenceId: reference.id }));
  }

  const reference = useAsync(async () => {
    if (!document.referenceId) return null;

    const rows = await http.client.get('/api/v1/verified-references', {
      params: {
        referenceId: document.referenceId,
      },
    }).then(r => r.data as VerifiedReference[]);

    if (rows.length <= 0) return null;
    return rows[0] as VerifiedReference;
  }, [ document.referenceId ]);

  const buttons = useMapping(() => {
    if (saveFn.loading) {
      return <Loading />
    }
    if (submitFn.loading) {
      return <Loading />
    }

    if (hasChanges || !document.referenceId) {
      return <>
        <Button onClick={() => saveFn.trigger()}>Save</Button>
        <Button disabled={deleteFn.loading} onClick={() => deleteFn.trigger()}>Delete</Button>
      </>
    }

    switch (document.status) {
      case DocumentStatus.Idle:
        return <Button disabled={deleteFn.loading} onClick={() => deleteFn.trigger()}>Delete</Button>

      case DocumentStatus.Approved:
        return <Text center>Document has been approved!</Text>

      case DocumentStatus.Declined:
        return <Text center>Document has been declined!</Text>

      case DocumentStatus.PendingReview:
        // If document.updatedAt is more recent than verified records, then submit should be available.
        if (reference.value && new Date(document.updatedAt) > new Date(reference.value.updatedAt) && document.status !== DocumentStatus.PendingReview) {
          // Document is more recent than approved document.
          return <>
            <Button onClick={() => submitFn.trigger()}>Submit Updated</Button>
          </>
        }

        return <Text center>Pending Review</Text>

      case DocumentStatus.Error:
        if (!document.error) return <Text center>Sorry! Something ha gone wrong.</Text>
        return errors.onError(document.error);
    }
  }, [
    document,
    hasChanges,
    patch,
    saveFn.loading,
    submitFn.loading,
    reference.value,
    reference.loading,
    deleteFn.loading,
  ]);

  return <>
    <div style={{ margin: '1rem', display: 'flex', flexDirection: 'column', width: '100%', gap: '1rem' }}>
      <Text>Upload document</Text>
      <input type='file' onChange={e => setFile(e.target?.files && e.target.files[0] || null)} />
      <div style={{ width: '100%', maxWidth: '40rem', display: 'flex', justifyContent: 'center' }}>
        <DocumentPreview file={file || undefined} documentId={document.id} />
      </div>
      <div>
      <div>
        <TextInput area placeholder="Description"
          value={patch.description || ''}
          setValue={description => setPatch(patch => ({ ...patch, description }))} />
      </div>
      <IndustriesDropdown
        query={{ }}
        tag={patch.industryTag || null}
        onSelected={industrySelected}
      />
      <SkillSelection
        query={{ industryTag: patch.industryTag || undefined }} 
        tags={patch.skills}
        onSelected={onSkillsSelected}
      />
      <div style={{ marginTop: '1rem' }} />
      {buttons}
      </div>
    </div>
  </>
}