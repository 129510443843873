import { ReactNode } from 'react';
import styles from './tooltip.module.scss';

export type TooltipProps = {
  tip?: ReactNode;
} & React.HTMLAttributes<HTMLElement>;

export const Tooltip = ({
  tip,
  children,
}: TooltipProps) => {
  return <div className={styles.tooltip}>
    <div className={styles.content}>
      {tip}
    </div>
    {children}
  </div>;
}