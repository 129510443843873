import { AxiosInstance } from "axios";
import { AsyncArgs, useAsync, useHttp, useMapping } from "../hooks";
import { useEffect } from "react";
import useEvents from "../hooks/use-events";
import { Emitter } from "event-emitter";
import { AppError, ErrorCode } from "./errors";
import { uploadImage } from "./files-api";

export type Question = {
  id: string;
  referenceId: string;
  question: string;
  answer: string | null;
}

export type QuestionInput = {
  resumeId: string;
  referenceId?: string;
}

export type QuestionPatch = {
  description?: string | null;
  fileId?: string | null;
  referenceId?: string | null;
  industryTag?: string | null;
  skills?: string[];
}

export type QuestionQueryParams = {
  referenceId?: string;
}

export class QuestionsApi {
  constructor (
    private client: AxiosInstance,
    private events: Emitter,
  ) {
    this.client.interceptors.response.use(null, err => {
      if (!err.response) return Promise.reject(err);

      if (!err.response.headers['content-type'].endsWith('/json')) return Promise.reject(err);
      const data = err.response.data;
      if ('code' in data) {
        return Promise.reject(new AppError(data.code, data.issues));
      }

      return Promise.reject(err);
    });
  }

  create = async (input: QuestionInput): Promise<Question> => {
    return await this.client.post('/api/v1/reference-questions', input)
      .then(r => r.data)
      .then(question => {
        this.events.emit('question.created', question);
        return question;
      });
  }

  update = async (questionId: string, patch: QuestionPatch): Promise<Question> => {
    return await this.client.patch(`/api/v1/reference-questions/${questionId}`, patch)
      .then(r => r.data)
      .then(question => {
        this.events.emit('question.updated', question);
        return question;
      });
  }

  find = async (query: QuestionQueryParams={}) => {
    return await this.client.get(`/api/v1/reference-questions`, {
      params: query,
    })
      .then(r => r.data as Question[]);
  }

  get = async (questionId: string) => {
    return await this.client.get(`/api/v1/reference-questions/${questionId}`)
      .then(r => r.data as Question);
  }

  delete = async (questionId: string): Promise<Question> => {
    return await this.client.delete(`/api/v1/reference-questions/${questionId}`)
      .then(r => r.data as Question)
      .then(question => {
        this.events.emit('question.deleted', question);
        return question;
      });
  }
}

export const useQuestionsApi = () => {
  const http = useHttp();
  const events = useEvents();

  return useMapping(() => {
    return new QuestionsApi(http.client, events);
  }, [ http.client ]);
}

export const useQuestions = (query: QuestionQueryParams={}) => {
  const questionsApi = useQuestionsApi();
  const events = useEvents();
  console.log(query);
  const task = useAsync(() => questionsApi.find(query), [ JSON.stringify(query) ]);

  useEffect(() => {
    const f = () => task.trigger(true);
    const onUpdate = x => {
      if (!task.value) return;
      if (!task.value.find(y => y.id === x.id)) return;

      task.setValue(
        task.value.map(y => {
          if (x.id === y.id) return x;
          return y;
        })
      );
    }

    events.on('question.updated',onUpdate);
    events.on('question.created', f);
    events.on('question.deleted', f);
    return () => {
      events.off('question.updated', onUpdate);
      events.off('question.created', f);
      events.off('question.deleted', f);
    }
  }, [ questionsApi, task.loading, task.value ]);

  return task;
}

export const useQuestion = (questionId: string) => {
  const questionsApi = useQuestionsApi();
  const events = useEvents();
  const task = useAsync(async () => {
    return await questionsApi.get(questionId);
  }, [ questionId ]);

  useEffect(() => {
    const f = (question: Question) => {
      if (question.id !== questionId) return;
      task.setValue(question);
    };
    events.on('question.deleted', f);
    events.on('question.updated', f);
    return () => {
      events.off('question.updated', f);
      events.off('question.deleted', f);
    }
  }, [ questionId, questionsApi ]);

  return task;
}