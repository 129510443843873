import { AxiosInstance } from "axios";
import { useAsync, useHttp, useMapping } from "../hooks";

export type ColorPalette = {
  backgroundColor?: string;
  panelColorPrimary?: string;
  topnavBackgroundColor?: string;
}


export type AppConfig = {
  title: string;
  palette: ColorPalette;
}

export class AppConfigClient {
  constructor(private client: AxiosInstance) { }

  get = async (): Promise<AppConfig> => {
    return await this.client.get(`/api/app-config`).then(r => r.data);
  }
}

export const useAppConfig = () => {
  const http = useHttp();

  return useAsync(async () => {
    return await new AppConfigClient(http.client).get();
  }, [ http.client ]);
}