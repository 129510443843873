import { Link } from "react-router-dom";
import { useMapping } from "../../hooks";
import { Document } from "../../io/documents-api"
import styles from './lists.module.scss';
import { Panel } from "../../ui/layout/Panel";
import { EditDocumentCard } from "../documents/edit-document-card";

const DocumentStatus = ({
  status,
}: {
  status: string,
}) => {
  const label = useMapping(() => {
    if (status === 'idle') return 'NotSubmitted';
    return status;
  }, [ status ]);

  return <div className={styles.documentStatus}>
    {label}
  </div>
}

const DocumentItem = ({
  document,
}: {
  document: Document;
}) => {
  return <Link to={`/documents/${document.id}`}>
    <Panel>
      <li key={`document-${document.id}`} className={styles.documentItem}>
        <DocumentStatus status={document.status} />
        <div className={styles.documentSubinfo}>
          <span className={styles.documentIndustry}>Industry: {document.industryTag}</span>
          <span className={styles.documentTags}>Skills: {document.skills}</span>
        </div>
        <span className={styles.documentDescription}>{document.description || '<No Description>'}</span>
        <object className={styles.documentThumb} data={`/api/v1/documents/${document.id}/file`} type='image/png'>
          <img style={{ height: '100%', float: 'right' }} src={'https://www.iconpacks.net/icons/1/free-document-icon-901-thumb.png'} alt='No Document' />
        </object>
      </li>
    </Panel>
  </Link>
}

export const DocumentList = ({
  documents,
  largeView,
}: {
  documents: Document[];
  largeView?: boolean;
}) => {
  const elems = useMapping(() => {
    if (largeView) return documents.map(x => {
      return <li>
        <Panel style={{ width: '40rem', display: 'flex' }}>
          <EditDocumentCard document={x} />
        </Panel>
      </li>
    });

    return documents.map(x => {
      return <DocumentItem document={x} />
    });
  }, [ documents ]);

  return <ul className={styles.list}>
    {elems}
  </ul>
}