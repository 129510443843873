import { Link } from "react-router-dom";
import { useAsync, useHttp, useMapping } from "../../hooks";
import styles from './lists.module.scss';
import { Panel } from "../../ui/layout/Panel";
import { Question } from "../../io/questions-api";
import { Icon } from "../../ui/imagery/Icon";
import { useEffect, useState } from "react";
import { TextInput } from "../../ui/input/TextInput";
import { Text } from "../../ui";
import { Button } from "../../ui/interactive/button";
import { Loading } from "../../ui/control/loading";
import panelStyles from '../../ui/layout/Panel.module.scss';
import clsx from "clsx";
import { Delegate } from "../../delegate";

const QuestionItem = ({
  question,
}: {
  question: Question;
}) => {
  return <>
    <Panel>
      <li key={`question-${question.id}`} className={styles.questionItem}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '.5rem', width: '100%', justifyContent: 'center' }}>
          <div style={{ display: 'flex', gap: '1rem' }}>
            Question: <div style={{ width: '100%', marginRight: '1rem', fontSize: '10px' }}>
              {question.question}
            </div>
          </div>
          <div style={{ display: 'flex', gap: '1rem' }}>
            Answer: <div style={{ width: '100%', marginRight: '1rem', fontSize: '10px' }}>
              {question.answer}
            </div>
          </div>
        </div>
      </li>
    </Panel>
  </>
}

export const QuestionList = ({
  questions,
}: {
  questions: Question[];
}) => {
  const elems = useMapping(() => {
    return questions.map(x => {
      return <QuestionItem question={x} />
    });
  }, [ questions ]);

  return <ul className={styles.list}>
    {elems}
  </ul>
}

const EditQuestionItem = ({
  question,
}: {
  question: Question;
}) => {
  const http = useHttp();
  const [ edit, setEdit ] = useState(false);
  const [ q, setQ ] = useState(() => question.question);
  const [ _question, setQuestion ] = useState(question);

  const onQuestionUpdate = (q: string) => {
    http.client.patch(`/api/v1/reference-questions/${question.id}`, {
      question: q,
    }).then(r => setQuestion(r.data));
  }  

  useEffect(() => {
    if (q !== question.question) onQuestionUpdate(q);
  }, [ q ]);

  const questionField = useMapping(() => {
    if (edit || _question.question.trim() === '') return <TextInput style={{ marginTop: '0' }} value={_question.question} onChange={e => setQ(e.target.value)}
      onSubmit={() => setEdit(false)}
    />
    return _question.question;
  }, [ edit ]);

  return <>
    <Panel>
      <li key={`question-${question.id}`} className={styles.questionEditItem}>
        <Icon icon='edit' onClick={() => setEdit(!edit)} className={styles.questionEditIcon} />
        Question: <div style={{ width: '100%', marginRight: '1rem' }}>
          {questionField}
        </div>
      </li>
    </Panel>
  </>
}

export const EditQuestionList = ({
  questions,
}: {
  questions: Question[];
}) => {
  const elems = useMapping(() => {
    return questions.map(x => {
      return <EditQuestionItem question={x} />
    });
  }, [ questions ]);

  return <ul className={styles.list}>
    {elems}
  </ul>
}

const ReviewQuestionItem = ({
  question,
  onSave,
}: {
  question: Question;
  onSave: Delegate<void>;
}) => {
  const http = useHttp();
  const [ answer, setAnswer ] = useState(question.answer);

  const update = useAsync(async () => {
    http.client.patch(`/api/v1/reference-questions/${question.id}`, {
      answer,
    });
    return true;
  }, [ question.id, answer ], {
    defer: true,
    default: null,
  });

  const onQuestionUpdate = () => {
    update.trigger(true);
  }

  useEffect(() => {
    return onSave.listenAsync(async () => update.trigger());
  }, [ onSave ]);

  const check = useMapping(() => {
    if (update.value === null) return <></>
    if (update.loading) return <Loading small />
    if (update.error) return <Text error>{update.error.message}</Text>
    return <Icon style={{ color: 'green' }} icon='check' />
  }, [ update.loading, update.error, update.value ]);

  return <>
    <li key={`question-${question.id}`} className={clsx(styles.questionReviewItem, panelStyles.panel)}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', width: '100%' }}>
        <div style={{ display: 'flex', gap: '1rem' }}>
          Question: <div style={{ width: '100%', marginRight: '1rem' }}>
            {question.question}
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          Answer: <div style={{ width: 'calc(100% - 1rem)' }}>
            <TextInput value={question.answer || ''} onChange={e => setAnswer(e.target.value)} onSubmit={onQuestionUpdate} />
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {check}
        </div>
      </div>
    </li>
  </>
}

export const ReviewQuestionList = ({
  questions,
  onSave,
}: {
  questions: Question[];
  onSave: Delegate<void>;
}) => {
  const elems = useMapping(() => {
    return questions.map(x => {
      return <ReviewQuestionItem question={x} onSave={onSave} />
    });
  }, [ questions ]);

  return <ul className={styles.reviewQuestionList}>
    {elems}
  </ul>
}