import { Link, useParams } from "react-router-dom";
import { Reference, ReferenceInput, useReference, useReferencesApi } from "../../io/references-api"
import { useAsync, useErrors, useHttp, useMapping } from "../../hooks";
import { useState } from "react";
import { TextInput } from "../../ui/input/TextInput";
import { Grid, Image, Text } from "../../ui";
import { Button } from "../../ui/interactive/button";
import { Toast } from "../../ui/interactive/toast";
import { Panel } from "../../ui/layout/Panel";
import { Modal } from "../../ui/modals/modal";
import { SaveButton } from "../buttons/save-button";
import { Page } from "../../ui/interactive/page";
import { useDocuments, useDocumentsApi } from "../../io/documents-api";
import { DocumentList } from "../lists/documents-list";
import { NewButton } from "../buttons/new-button";
import useEvents from "../../hooks/use-events";
import { Resume, useResumesApi } from "../../io/resumes-api";
import { Icon } from "../../ui/imagery/Icon";
import { EditQuestionList } from "../lists/question-list";

const ReferenceInfo = ({
  reference,
}: {
  reference: Reference;
}) => {
  console.log(reference);
  const [ input, setInput ] = useState<Partial<ReferenceInput>>(reference);
  const [ showDelete, showDeleteFn ] = useState(false);

  const referencesApi = useReferencesApi();
  const errors = useErrors();

  const deleteFn = useAsync(async () => {
    await referencesApi.delete(reference.id);
    showDeleteFn(false);
  }, [ reference.id ], {
    defer: true,
    default: null,
  });

  const update = useAsync(async () => {
    return await referencesApi.update(reference.id, input);
  }, [ JSON.stringify(input) ], {
    defer: true,
    default: null,
  });

  const toast = useMapping(() => {
    if (update.error) return <Toast show>{errors.onError(update.error)}</Toast>
    if (update.value !== null) return <Toast show>Updated!</Toast>
  }, [ update.error, update.value ]);


  const setContactName = (contactName: string) => setInput({
    ...input,
    contactName,
  });
  const setOccupation = (occupation: string) => setInput({
    ...input,
    occupation,
  });
  const setPhoneNumber = (phoneNumber: string) => setInput({
    ...input,
    phoneNumber,
  });

  return <div style={{ width: '100%', maxWidth: '40rem' }}>
    {toast}
    <div style={{ margin: '1rem', display: 'flex', gap: '1rem', justifyContent: 'center', flexDirection: 'column' }}>
      <TextInput placeholder="Contact Name" value={input.contactName} setValue={setContactName} />
      <TextInput placeholder="Occuptation" value={input.occupation || ''} setValue={setOccupation} />
      <TextInput placeholder="Phone Number" value={input.phoneNumber} setValue={setPhoneNumber} />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <SaveButton task={update} />
        <Button onClick={() => showDeleteFn(true)}>Delete</Button>
      </div>
    </div>
    <Modal enabled={showDelete} onClose={() => showDeleteFn(false)}>
      <Panel>
        <div style={{ marginTop: '1rem' }} />
        <Text center>Are you sure you wish to delete this?</Text>
        <Button onClick={() => showDeleteFn(false)}>Cancel</Button>
        <Button onClick={() => deleteFn.trigger()}>Confirm</Button>
      </Panel>
    </Modal>
  </div>
}

const AddQuestionButton = () => {
  const [ enabled, setEnabled ] = useState(false);

  const onClick = () => {
    setEnabled(true);
  }

  return <Button style={{ display: 'flex', alignItems: 'center' }} onClick={onClick}>
    <Icon style={{ margin: 'auto' }} icon="add" /><Text>New</Text>
    <Modal enabled={enabled} onClose={() => setEnabled(false)}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div>
          <h4>Question</h4>
        </div>
        <TextInput  />
        <Button onClick={() => setEnabled(false)}>Close</Button>
      </div>
    </Modal>
  </Button>
}

const QuestionsPanel = ({
  referenceId,
}: {
  referenceId: string;
}) => {
  const http = useHttp();
  const questions = useAsync(async () => {
    const resp = await http.client.get('/api/v1/reference-questions', {
      params: {
        referenceId,
      },
    });
    return resp.data;
  }, [ referenceId ]);

  const create = useAsync(async () => {
    const question = await http.client.post('/api/v1/reference-questions', {
      referenceId,
      question: '',
    });
    questions.trigger(true);
  }, [ referenceId ], {
    defer: true,
    default: null,
  });

  return <Panel>
    <h4 style={{ marginLeft: '1rem' }}>Questions</h4>
    <Button style={{ display: 'flex', alignItems: 'center' }} onClick={() => create.trigger()}>
      <Icon style={{ margin: 'auto' }} icon="add" /><Text>New</Text>
    </Button>
    <EditQuestionList questions={questions.value || []} />
  </Panel>
}

const DocumentsPanel = ({
  reference,
}: {
  reference: Reference;
}) => {
  const documents = useDocuments({
    referenceId: reference.id,
  });

  const documentsApi = useDocumentsApi();
  const create = useAsync(async () => {
    await documentsApi.create({
      resumeId: reference.resumeId,
      referenceId: reference.id,
    });
  }, [ reference.id ], {
    defer: true,
    default: null,
  });

  return <Panel>
    <h4 style={{ marginLeft: '1rem' }}>Documents</h4>
    <div>
      <NewButton task={create} />
    </div>
    <DocumentList documents={documents.value || []} />
  </Panel>
}

const Loaded = ({
  resume,
  reference,
}: {
  resume: Resume;
  reference: Reference;
}) => {
  return <Page>
    <Panel>
      <div style={{ margin: '1rem' }}>
        <Link to={`/resumes/${resume.id}/edit`}>
          <span style={{ color: '#1e5f85' }}>Edit Resume ({resume.slug})</span>
        </Link>
        <span style={{ color: 'grey' }}>{' >'} Reference ({reference.contactName})</span>
      </div>
    </Panel>
    <Panel>
      <h4 style={{ marginLeft: '1rem' }}>Information</h4>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <ReferenceInfo reference={reference} />
      </div>
    </Panel>
    <QuestionsPanel referenceId={reference.id} />
    <DocumentsPanel reference={reference} />
  </Page>
}

export const ReferencePage = () => {
  const {
    referenceId,
  } = useParams() as any;
  const referencesApi = useReferencesApi();
  const resumesApi = useResumesApi();

  const task = useAsync(async () => {
    const reference = await referencesApi.get(referenceId);
    const resume = await resumesApi.get(reference.resumeId);
    return {
      document,
      reference,
      resume,
    };
  });

  return useMapping(() => {
    if (task.error) return <>{task.error.message}</>
    if (task.loading) return <></>
    return <Loaded
      resume={task.value.resume}
      reference={task.value.reference}
    />
  }, [ task.error, task.value ]);
}