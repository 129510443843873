import { useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import './App.css';
import { Auth0Provider, AuthProvider, EventsProvider, HttpProvider, useAuth, useErrors, useMapping } from './hooks';
import { AppError, ErrorCode, Issue, IssueCode } from './io/errors';
import { Text } from './ui';
import { Topnav } from './ui/topnav';
import { HomePage } from './views/pages/home-page';
import { ResumePage } from './views/pages/resume-page';
import { CreateResumePage } from './views/pages/create-profile';
import { EditResumePage } from './views/pages/edit-resume';
import LoginPage from './views/pages/login-page';
import { LandingPage } from './views/pages';
import { LogoutPage } from './views/pages/logout-page';
import { ReferenceAuthorizePage } from './views/pages/reference-authorize-page';
import { ReferenceReviewPage } from './views/pages/reference-review-page';
import { TokensPage } from './views/pages/tokens-page';
import { SearchPage } from './views/pages/search-page';
import { ReferencePage } from './views/pages/reference-page';
import { DocumentPage } from './views/pages/document-page';

const IssueView = (issue: Issue) => {
  switch (issue.issue) {
    case IssueCode.AlreadyExists:
      if (issue.fieldName === 'slug') {
        return 'Duplicate contact name.';
      }
  }
}

const AdminRoutes = () => {
  return <>
    <Topnav />
    <Routes>
      <Route path="/references/:referenceId/authorize" element={<ReferenceAuthorizePage />} />
      <Route path="/references/:referenceId/review" element={<ReferenceReviewPage />} />
      <Route path="/references/:referenceId" element={<ReferencePage />} />
      <Route path="/documents/:documentId" element={<DocumentPage />} />
      <Route path="/resumes/:resumeId/edit" element={<EditResumePage />} />
      <Route path="/create/resume" element={<CreateResumePage />} />
      <Route path="/search" element={<SearchPage />} />
      <Route path="/tokens" element={<TokensPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/logout" element={<LogoutPage />} />
      <Route path="/:slug" element={<ResumePage />} />
      <Route path="/" element={<HomePage />} />
    </Routes>
  </>
}

const BasicRoutes = () => {
    // <Route path="/references/:referenceId/authorize" element={<ReferenceAuthorizePage />} />
  return <>
    <Topnav />
    <Routes>
      <Route path="/resumes/:slug/reference-request/:code" element={<ReferenceAuthorizePage />} />
      <Route path="/references/:referenceId/review" element={<ReferenceReviewPage />} />
      <Route path="/search" element={<SearchPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/logout" element={<LogoutPage />} />
      <Route path="/:slug" element={<ResumePage />} />
      <Route path="/" element={<HomePage />} />
    </Routes>
  </>
}

const UnauthorizedRoutes = () => {
  return <Routes>
    <Route path="/resumes/:slug/reference-request/:code" element={<ReferenceAuthorizePage />} />
    <Route path="/references/:referenceId/review" element={<ReferenceReviewPage />} />
    <Route path="/references/:referenceId/authorize" element={<ReferenceAuthorizePage />} />
    <Route path="/references/:referenceId" element={<ResumePage />} />
    <Route path="/search" element={<SearchPage />} />
    <Route path="/login" element={<LoginPage />} />
    <Route path="/logout" element={<LogoutPage />} />
    <Route path="/:slug" element={<ResumePage />} />
    <Route path="/" element={<LandingPage />} />
  </Routes>
}

const Routing = () => {
  const errors = useErrors();
  const auth = useAuth();

  const [ searchParams ] = useSearchParams();
  const location = useLocation();
  const nav = useNavigate();

  useEffect(() => {
    const _target = searchParams.get('target');
    if (!_target) return;

    const target = decodeURIComponent(_target);
    if (location.pathname === target) return;

    nav(target, { replace: true });
  }, [ searchParams, location]);

  useEffect(() => {
    errors.listen(err => {
      if (err instanceof AppError) {
        const issueViews = err.issues.map(IssueView).join(' ');

        switch (err.code) {
          case ErrorCode.Conflict:
            return <Text center error>Conflict: {issueViews}</Text>
        }
      }
      return <Text center error>{err.message}</Text>
    });
  }, [ ]);

  return useMapping(() => {
    if (auth.user) {
      if (auth.user.roles?.includes('admin')) return <AdminRoutes />
      return <BasicRoutes />
    }

    return <UnauthorizedRoutes />
  }, [ auth.user ]);
}

const App = () => {
  return <>
    <EventsProvider>
    <HttpProvider>
    <BrowserRouter>
    <AuthProvider>
      <Routing />
    </AuthProvider>
    </BrowserRouter>
    </HttpProvider>
    </EventsProvider>
  </>
}

export default App;
