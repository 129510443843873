import { useNavigate } from 'react-router-dom';
import { Icon } from './imagery/Icon';
import { useSidenav } from './interactive/sidenav';
import { Tooltip } from './interactive/tooltip';
import styles from './styles.module.scss';
import { FontSize, Text } from './typography/Text';
import { Image } from './imagery';
import { useAsync, useAuth, useMapping, useQueryParam } from '../hooks';
import { useAppConfig } from '../io/app-config-api';
import { Button } from './interactive/button';

const LogoutIcon = () => {
  const auth = useAuth();
  const logout = useAsync(auth.logout, [ auth.logout ], {
    defer: true,
    default: null,
  });

  return useMapping(() => {
    if (logout.loading) return <Icon large icon="busy" />

    return <Tooltip tip="Logout">
      <Icon large icon="logout" onClick={() => logout.trigger(false)} />
    </Tooltip>
  }, [ logout.loading ]);
}

export const Topnav = () => {
  const navigate = useNavigate();
  // const sidenav = useSidenav();
  const auth = useAuth();
  const appConfig = useAppConfig();

  const icons = useMapping(() => {
    if (!auth.user) return [];

    return [
      <Tooltip tip='Search'>
        <Icon large icon='search' onClick={() => navigate('/')} />
      </Tooltip>,
      auth.user ?
        <LogoutIcon />
        :
        <Tooltip tip='Login'>
          <Icon large icon='login' onClick={() => navigate('/login')}/>
        </Tooltip>,
    ];
  }, [ auth.user ]); 

  const title = useMapping(() => {
    if (appConfig.value) return appConfig.value.title;
  }, [ appConfig.loading, appConfig.value ]);

  return <header className={styles.topnav}>
    <div className={styles.logo}>
      <Image
        className={styles.logoImg}
        objectFit='contain'
        onClick={() => navigate('/')}
        src="/uptime_logo.png"
      />
    </div>
    {/*
    <Icon
      large
      icon='menu'
      className={styles.menuIcon}
      onClick={sidenav.flip}
    />
    */}
    <Text
      onClick={() => navigate('/')}
      className={styles.topTitle}
      fontSize={FontSize.Large} center>
      {title}
    </Text>
    <div className={styles.icons}>
      {icons}
    </div>
  </header>
}