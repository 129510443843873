import { ReactNode, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Async, useAsync, useErrors, useHttp, useMapping, useQueryParam } from "../../hooks";
import { Document, DocumentStatus, useDocuments, useDocumentsApi } from "../../io/documents-api";
import { Reference, ReferenceInput, useReferences, useReferencesApi } from "../../io/references-api";
import { Resume, ResumePatch, useResume, useResumesApi } from '../../io/resumes-api';
import { Grid, Image, Text } from "../../ui";
import { Loading } from "../../ui/control/loading";
import { TextInput } from "../../ui/input/TextInput";
import { Button } from "../../ui/interactive/button";
import { Page } from "../../ui/interactive/page";
import { Toast } from "../../ui/interactive/toast";
import { Panel } from "../../ui/layout/Panel";
import { BackButton } from "../buttons/back-button";
import { NewButton } from "../buttons/new-button";
import { SaveButton } from "../buttons/save-button";
import { SubmitButton } from "../buttons/submit-button";
import { FileCard } from "../cards/file-card";
import { ReferenceContactCard } from "../cards/reference-contact-card";
import { EditDocumentCard } from "../documents/edit-document-card";
import { EditResumeImagePanel } from "../panels/edit-resume-image-panel";
import { EditResumeInfoPanel } from "../panels/edit-resume-info-panel";
import { ReferenceReviewSection } from "../references/references-review-section";
import { Modal } from "../../ui/modals/modal";
import { ShowApprovedButton } from "../buttons/show-approved-button";
import { ReferenceList } from "../lists/reference-list";
import { DocumentList } from "../lists/documents-list";

const ReferenceCard = ({
  reference,
}: {
  reference: Reference;
}) => {
  const [ input, setInput ] = useState<Partial<ReferenceInput>>(reference);
  const [ showDelete, showDeleteFn ] = useState(false);

  const referencesApi = useReferencesApi();
  const errors = useErrors();

  const deleteFn = useAsync(async () => {
    await referencesApi.delete(reference.id);
    showDeleteFn(false);
  }, [ reference.id ], {
    defer: true,
    default: null,
  });

  const update = useAsync(async () => {
    return await referencesApi.update(reference.id, input);
  }, [ JSON.stringify(input) ], {
    defer: true,
    default: null,
  });

  const toast = useMapping(() => {
    if (update.error) return <Toast show>{errors.onError(update.error)}</Toast>
    if (update.value !== null) return <Toast show>Updated!</Toast>
  }, [ update.error, update.value ]);


  const setContactName = (contactName: string) => setInput({
    ...input,
    contactName,
  });
  const setOccupation = (occupation: string) => setInput({
    ...input,
    occupation,
  });
  const setPhoneNumber = (phoneNumber: string) => setInput({
    ...input,
    phoneNumber,
  });

  return <Panel style={{ width: '30rem', maxWidth: '40rem' }}>
    {toast}
    <div style={{ margin: '1rem', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
      <TextInput placeholder="Contact Name" value={input.contactName} setValue={setContactName} />
      <TextInput placeholder="Occuptation" value={input.occupation || ''} setValue={setOccupation} />
      <TextInput placeholder="Phone Number" value={input.phoneNumber} setValue={setPhoneNumber} />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <SaveButton task={update} />
        <Button onClick={() => showDeleteFn(true)}>Delete</Button>
      </div>
    </div>
    <Modal enabled={showDelete} onClose={() => showDeleteFn(false)}>
      <Panel>
        <div style={{ marginTop: '1rem' }} />
        <Text center>Are you sure you wish to delete this?</Text>
        <Button onClick={() => showDeleteFn(false)}>Cancel</Button>
        <Button onClick={() => deleteFn.trigger()}>Confirm</Button>
      </Panel>
    </Modal>
  </Panel>
}

const ReferencesPanel = ({
  resumeId,
}: {
  resumeId: string;
}) => {
  const errors = useErrors();
  const references = useReferences({
    resumeId,
  });

  const referencesApi = useReferencesApi();
  const create = useAsync(async () => {
    await referencesApi.create({
      resumeId,
      contactName: '',
      phoneNumber: '',
      occupation: null,
    });
  }, [ ], {
    defer: true,
    default: null,
  });

  const cards = useMapping(() => {
    if (references.loading) return <Loading />
    if (references.error) return errors.onError(references.error);

    const cards = references.value.map(reference => {
      if (reference.deletedAt !== null) {
        return <></>
      }

      return <Link to={`/references/${reference.id}`}>
        <li>
          <Text>Reference: {reference.contactName}</Text>
        </li>
      </Link>

      // return <ReferenceCard reference={reference} />
    });

    return <>
      <ReferenceList references={references.value} />
    </>
  }, [ references.loading, references.value ]);

  return <>
    <Panel style={{ width: '100%', display: 'block', overflowX: 'scroll' }}>
      <NewButton task={create} />
    </Panel>
    <Grid>
      {cards}
    </Grid>
  </>
}

const DocumentPreview = ({
  file,
  documentId,
}: {
  file?: File;
  documentId: string;
}) => {
  const image = useMapping(() => {
    if (file) return <FileCard file={file} />
    return <Image src={`/api/v1/documents/${documentId}/file`} />
  }, [ file, documentId ]);

  return <div style={{ width: '50%' }}>
    {image}
  </div>
}

const SubmissionsView = ({
  reference,
  showApproved,
}: {
  reference: Reference;
  showApproved: boolean;
}) => {
  const errors = useErrors();
  const documents = useDocuments({
    resumeId: reference.resumeId,
    statusIn: [
      DocumentStatus.Idle,
      DocumentStatus.Error,
      DocumentStatus.Declined,
      showApproved && DocumentStatus.Approved || null,
    ].filter(x => x !== null) as DocumentStatus[],
  });

  const documentsView = useMapping(() => {
    if (documents.loading) return <Loading />
    if (documents.error) return errors.onError(documents.error);
    const docs = documents.value.map(document => {
      if (document.referenceId !== reference.id) return null;
      if (document.status === DocumentStatus.PendingReview) return null;

      // return <EditDocumentCard document={document} />
      return document;
    }).filter((x): x is Document => x !== null);
    return <DocumentList documents={docs} largeView />
  }, [ documents.loading, documents.error, documents.value ]);

  return <>
    <Grid>
      {documentsView}
    </Grid>
  </>
}

const DocumentUploadPanel = ({
  resumeId,
}: {
  resumeId: string;
}) => {
  const errors = useErrors();
  const [ selectedReference, setSelectedReference ] = useState<Reference | null>(null);
  const [ showApproved, setShowApproved ] = useState(() => false);

  const documentsApi = useDocumentsApi();
  const references = useReferences({
    resumeId,
  });
  const create = useAsync(async () => {
    await documentsApi.create({
      resumeId,
      referenceId: selectedReference?.id,
    });
  }, [ resumeId, selectedReference ], {
    defer: true,
    default: null,
  });

  const http = useHttp();
  const submitFn = useAsync(async () => {
    if (selectedReference === null) throw new Error('invalid-reference');


    // Walk through idle documents and mark them for review.
    const documents = await documentsApi.find({
      resumeId,
      statusIn: [
        DocumentStatus.Idle,
      ],
    }).then(docs => docs.filter(x => x.referenceId === selectedReference.id));

    for (const doc of documents) {
      if (doc.status === DocumentStatus.Idle) {
        const docIndex = documents.findIndex(x => x.id === doc.id);
        if (!doc.industryTag) throw new Error(`
          Invalid industry field on document number:${docIndex}
        `);
        if (!doc.skills || doc.skills.length === 0) throw new Error(`
          Invalid industry field on document number:${docIndex}
        `);

        await documentsApi.submit(doc.id);
      }
    }

    const resp = await http.client.post(`/api/v1/reference-verification`, {
      referenceId: selectedReference.id,
    });
    return resp.data as {
      link: string;
    };
  }, [ selectedReference ], {
    defer: true,
    default: null,
  });

  const submitModal = useMapping(() => {
    if (submitFn.error) return errors.onError(submitFn.error);
    if (!submitFn.value) return <></>

    const link = new URL(submitFn.value.link, window.origin).toString();

    const onClick = async () => {
      await navigator.clipboard.writeText(link);
      alert('Link has been copied to clipboard');
    }

    return <Panel>
      <div style={{ gap: '1rem', margin: '1rem', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Text center>Copy link and send to your reference.</Text>
        <code style={{ backgroundColor: 'lightgrey', borderRadius: '3px' }}>
          <div style={{ padding: '1rem' }}>
            <div onClick={onClick}>
              {link}
            </div>
          </div>
        </code>
      </div>
    </Panel>
  }, [ submitFn.loading ]);

  const referenceViews = useMapping(() => {
    if (references.value) return references.value.map(reference => {
      return <ReferenceContactCard
        reference={reference}
        onClick={setSelectedReference}
      />
    });
    if (references.loading) return <Loading />
    if (references.error) return errors.onError(references.error);
  }, [ references.loading, references.error, references.value ]);

  const contentView = useMapping(() => {
    if (selectedReference === null) {
      return <div style={{ display: 'flex', maxWidth: '40rem', flexDirection: 'column' }}>
          {referenceViews}
      </div>
    }

    return <Grid center>
      <SubmissionsView reference={selectedReference} showApproved={showApproved} />
    </Grid>
  }, [ selectedReference, referenceViews, showApproved ]);

  const buttons = useMapping(() => {
    if (selectedReference === null) {
      return <>
        <div style={{ margin: '1rem' }} >
          <Text>Select a reference below.</Text>
        </div>
      </>
    }

    return <>
      <BackButton onClick={() => setSelectedReference(null)} />
      <NewButton task={create} />
      <SubmitButton disabled={submitFn.loading} task={submitFn} />
      <ShowApprovedButton active={showApproved} onClick={() => setShowApproved(x => !x)} />
    </>
  }, [ selectedReference, showApproved ]);

  return <>
    <Modal enabled={submitFn.value !== null} onClose={() => submitFn.setValue(null)}>
      {submitModal}
    </Modal>
    <Panel style={{ width: '100%', display: 'flex', gap: '.5rem' }}>
      {buttons}
    </Panel>
    {contentView}
  </>
}


const EditResumeInfo = ({
  resume,
  setResume,
}: {
  resume: Async<Resume>;
  setResume: (x: Resume) => void;
}) => {
  const errors = useErrors();
  const [ toast, setToast ] = useState<ReactNode | null>(null);
  const [ patch, setPatch ] = useState<Partial<ResumePatch>>({});
  const resumesApi = useResumesApi();
  const update = useAsync(async () => {
    if (!resume.value) return null;

    const updated = await resumesApi.update(resume.value.id, patch);
    resume.setValue(updated);
    setPatch(updated);
    setToast(<Panel style={{ margin: 'auto', display: 'flex', width: '10rem', height: '5rem', backgroundColor: 'green' }}>
      <Text style={{ margin: 'auto' }} center>Updated</Text>
    </Panel>);
    return true;
  }, [ patch ], {
    defer: true,
    default: null,
  });

  useEffect(() => {
    if (!resume.value) return;

    setPatch(resume.value);
  }, [ resume.value ]);

  return useMapping(() => {
    if (resume.error) return errors.onError(resume.error);
    if (!resume.value) return <Text>Resume Not Found</Text>

    const errorElem = update.error ? errors.onError(update.error) : null;
    const loadingElem = resume.loading ? <Loading floating /> : null;
    const hasChanges = (() => {
      if (!resume.value) return false;

      for (const key of Object.keys(patch)) {
        if ((patch as any)[key] !== (resume.value as any)[key]) return true;
      }

      return false;
    })();

    return <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
      <Toast show={!update.loading && update.value !== null}>
        {toast}
      </Toast>
      <div style={{ marginBottom: '1rem', position: 'absolute', right: 0, left: 0, bottom: 0 }}>
        {errorElem}
      </div>
        {loadingElem}
      <EditResumeInfoPanel
        resume={resume.value}
        setResume={resume.setValue}
      />
      <EditResumeImagePanel resume={resume.value} />
    </div>
  }, [ JSON.stringify(patch), resume.loading, resume.error, JSON.stringify(resume.value), update.loading, update.error ]);
}

enum Tab {
  Info = 'info',
  Submissions = 'submissions',
  References = 'references',
  Pending = 'pending',
}

export const EditResumePage = () => {
  const {
    resumeId,
  } = useParams();
  const errors = useErrors();
  const resume = useResume(resumeId as string);

  const {
    value: tab,
    setParam: setTab,
  } = useQueryParam<Tab>('tab', x => {
    if (!Object.values(Tab).includes(x as Tab)) return Tab.Info;
    return x as Tab;
  });

  const tabView = useMapping(() => {
    switch (tab || Tab.Info) {
      case Tab.Info:
        return <EditResumeInfo resume={resume} setResume={resume.setValue} />

      case Tab.Submissions:
        return <DocumentUploadPanel resumeId={resumeId as string} />

      case Tab.References:
        return <ReferencesPanel resumeId={resumeId as string} />

      case Tab.Pending:
        return <ReferenceReviewSection resumeId={resumeId as string} />
    }
  }, [ tab, JSON.stringify(resume) ]);

  const previewLink = useMapping(() => {
    if (!resume.value) return null;

    return <Link to={`/${resume.value.slug}`}>
      <Button>Preview</Button>
    </Link>
  }, [ resume.value ]);

  return <Page>
    <Panel style={{ width: '100vw', overflowX: 'scroll' }}> 
      <div style={{ width: 'max-content' }} >
        <Button disabled={tab === Tab.Info} onClick={() => setTab(Tab.Info)}>Info</Button>
        <Button disabled={tab === Tab.Submissions} onClick={() => setTab(Tab.Submissions)}>Submissions</Button>
        <Button disabled={tab === Tab.References} onClick={() => setTab(Tab.References)}>References</Button>
        <Button disabled={tab === Tab.Pending} onClick={() => setTab(Tab.Pending)}>Pending</Button>
        {previewLink}
      </div>
    </Panel>
    {tabView}
  </Page>
}