import { Link, useParams } from "react-router-dom"
import { Page } from "../../ui/interactive/page"
import { useDocuments, useDocumentsApi, Document, DocumentStatus } from "../../io/documents-api";
import { useAsync, useErrors, useMapping } from "../../hooks";
import { Loading } from "../../ui/control/loading";
import { Image, Card, Grid, Text } from "../../ui";
import { Button } from "../../ui/interactive/button";
import { Panel } from "../../ui/layout/Panel";
import { ApproveDocumentCard } from "../documents/approve-document-card";
import { useFindResume, useResume, useResumes } from "../../io/resumes-api";
import { useQuestions } from "../../io/questions-api";
import { useState } from "react";
import { ReviewQuestionList } from "../lists/question-list";
import { Delegate, EventDelegate } from "../../delegate";

const DocumentCard = ({
  document,
}: {
  document: Document;
}) => {
  return <ApproveDocumentCard document={document} />
}

export const ReferenceReviewPage = () => {
  const {
    referenceId,
  } = useParams();
  const errors = useErrors();
  const [ questionsAnswered, setQuestionsAnswered ] = useState(false);
  const documents = useDocuments({
    referenceId,
    statusIn: [
      DocumentStatus.PendingReview,
    ],
  });

  const questions = useQuestions({
    referenceId,
  });

  const resume = useFindResume({
    referenceId,
  });
  const onSave = new EventDelegate<void>();

  const continueButton = useMapping(() => {
    if (!resume.value) return <Button>
      <Loading />
    </Button>

    return <Link to={`/${resume.value.slug}`}>
      <Button>
        {resume.value.contactName}'s Resume
      </Button>
    </Link>
  }, [ resume.value ]);

  const documentViews = useMapping(() => {
    if (documents.loading) return <Loading />
    if (documents.error) return errors.onError(documents.error);

    if (questions.loading) return <></>
    if (questions.error) return errors.onError(questions.error);

    const docs = 
      documents.value
      .filter(x => [
        DocumentStatus.PendingReview
      ].includes(x.status));

    if (docs.length === 0) {
      const handleSave = async () => {
        try {
          await onSave.trigger();
          setQuestionsAnswered(true);
        }
        catch (error: any) {
          alert(error.message);
        }
      }

      if (!questionsAnswered) return <>
        <div style={{ margin: '1rem' }} />
        <Text center>Please answer the following questions about {resume.value?.contactName}</Text>
        <div style={{ margin: '1rem' }} />
        <ReviewQuestionList questions={questions.value} onSave={onSave} />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={handleSave}>Save</Button>
        </div>
      </>

      return <>
        <div style={{ margin: '1rem' }} />
        <Text center>All documents have been reviewed.</Text>
        <div style={{ margin: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '3rem', justifyContent: 'center' }}>
          {continueButton}
        </div>
      </>
    }

    const docViews = docs
      .map(document => {
        return <DocumentCard document={document} />
      });

    return <>
      <div style={{ margin: '1rem' }} />
      <Text center>Please review the following documents.</Text>
      <div style={{ margin: '1rem' }} />
      <div style={{ margin: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '3rem', justifyContent: 'center' }}>
        {docViews}
      </div>
    </>
  }, [ questionsAnswered, documents.loading, documents.error, documents.value, continueButton, questions.loading, questions.error, questions.value ]);

  return <Page>
    <Panel>
      {documentViews}
    </Panel>
  </Page>
}