import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAsync, useAuth, useErrors, useHttp, useMapping, useQueryParam } from "../../hooks";
import { AppError, IssueCode } from "../../io/errors";
import { useReference, useReferencesApi } from "../../io/references-api";
import { Grid, Text } from "../../ui";
import { TextInput } from "../../ui/input/TextInput";
import { Button } from "../../ui/interactive/button";
import { Page } from "../../ui/interactive/page";
import { Panel } from "../../ui/layout/Panel";
import { useFindResume, useResumesApi } from "../../io/resumes-api";

export const ReferenceAuthorize = ({
  referenceId,
  tokenId,
}: {
  referenceId: string;
  tokenId: string;
}) => {
  // const {
  //   referenceId,
  // } = useParams() as {
  //   referenceId: string;
  // };
  // const {
  //   value: token
  // } = useQueryParam("token", x => x);

  const reference = useReference(referenceId);

  const [ contactName, setContactName ] = useState('');
  const [ phoneNumber, setPhoneNumber ] = useState('');
  const [ occupation, setOccupation ] = useState('');

  useEffect(() => {
    if (!reference.value) return;
    setContactName(reference.value.contactName);
    setOccupation(reference.value.occupation || '');
  }, [ reference.value?.contactName ]);

  const http = useHttp();
  const errors = useErrors();
  const nav = useNavigate();
  const auth = useAuth();

  const authorize = useAsync(async () => {
    await http.client.post(`/api/v1/references/${referenceId}/authorize`, {
      contactName,
      token: tokenId,
      phoneNumber,
      occupation,
    }).then(r => r.data);
    nav(`/references/${referenceId}/review`);
    return true;
  }, [ contactName, phoneNumber, occupation, tokenId ], {
    defer: true,
    default: null,
  });

  const view = useMapping(() => {
    if (auth.user) {
      return <Text style={{ margin: '2rem' }} center>You must logout to authorize using this link.</Text>
    }

    if (authorize.loading) return <Text>Please wait</Text>

    const error = ((error?: Error) => {
      if (error) {
        if (!(error instanceof AppError)) return errors.onError(error);

        if (error.issues.find(x => x.issue === IssueCode.IncorrectPhoneNumber)) {
          return <Text error center>The information provided is incorrect please try another attempt.</Text>
        }

        return errors.onError(error);
      }
      return null;
    })(authorize.error);

    return <div style={{ margin: '1rem' }}>
      <Text center>Please verify the following information.</Text>
      {error}
      <div style={{ marginTop: '1rem' }} />
      <div style={{ margin: 'auto', maxWidth: '40rem' }}>
        <TextInput placeholder='Contact Name' value={contactName} setValue={setContactName} />
        <TextInput placeholder='Phone Number' value={phoneNumber} setValue={setPhoneNumber} />
        <TextInput placeholder='Occupation' value={occupation} setValue={setOccupation} />
        <Grid center>
          <Button onClick={() => authorize.trigger()}>Submit</Button>
        </Grid>
      </div>
    </div>
  }, [ auth.user, authorize.loading, authorize.error, authorize.value, contactName, phoneNumber, occupation ]);

  return <Page>
    <Panel>
      {view}
    </Panel>
  </Page>
}

export const ReferenceAuthorizePage = () => {
  const {
    slug,
    code,
  } = useParams() as {
    slug: string;
    code: string;
  };

  const resumesApi = useResumesApi();
  const http = useHttp();
  const task = useAsync(async () => {
    const resume = await resumesApi.findOne({
      slug,
    });
    const resp = await http.client.post('/api/v1/reference-code-authorization', {
      resumeId: resume.id,
      code,
    });
    const res = resp.data;

    return <ReferenceAuthorize
      tokenId={res.tokenId}
      referenceId={res.referenceId}
    />
  }, [ slug, code ]);

  return useMapping(() => {
    if (task.error) return <>{task.error.message}</>
    if (task.loading) return <></>
    return task.value;
  }, [ task.value, task.error ]);
}