export type Action<A> = (value: A) => void;
export type AsyncAction<A> = (value: A) => Promise<void>;

export interface Delegate<A> {
  listen: (action: Action<A>) => Action<void>;
  listenAsync: (action: AsyncAction<A>) => Action<void>;
}

export class EventDelegate<A> implements Delegate<A> {
  private actions: Action<A>[] = [];
  private asyncActions: AsyncAction<A>[] = [];

  trigger = async (value: A) => {
    for (const action of this.actions) {
      action(value);
    }

    for (const asyncAction of this.asyncActions) {
      await asyncAction(value);
    }
  }

  listen = (action: Action<A>) => {
    this.actions.push(action);
    return () => {
      this.actions = this.actions.filter(x => x !== action);
    }
  }

  listenAsync = (action: AsyncAction<A>) => {
    this.asyncActions.push(action);
    return () => {
      this.asyncActions = this.asyncActions.filter(x => x !== action);
    }
  }
}