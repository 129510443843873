import { Link, useNavigate, useParams } from "react-router-dom";
import { useDocument, Document, useDocumentsApi } from "../../io/documents-api";
import { useAsync, useMapping } from "../../hooks";
import { Page } from "../../ui/interactive/page";
import { Panel } from "../../ui/layout/Panel";
import { EditDocumentCard } from "../documents/edit-document-card";
import { useEffect } from "react";
import { Reference, useReferencesApi } from "../../io/references-api";
import { Resume, useResumesApi } from "../../io/resumes-api";

const Loaded = ({
  resume,
  reference,
  document,
}: {
  resume: Resume;
  reference: Reference;
  document: Document;
}) => {
  return <Page>
    <Panel>
      <div style={{ margin: '1rem' }}>
        <Link to={`/resumes/${resume.id}/edit`}>
          <span style={{ color: '#1e5f85' }}>Edit Resume ({resume.slug})</span>
        </Link>
        <Link to={`/references/${reference.id}`}>
          <span style={{ color: '#1e5f85' }}>{' >'} Reference ({reference.contactName})</span>
        </Link>
        <span style={{ color: 'grey' }}>{' >'} Document</span>
      </div>
    </Panel>
    <Panel>
      <EditDocumentCard document={document} />
    </Panel>
  </Page>
}

export const DocumentPage = () => {
  const {
    documentId,
  } = useParams() as any;
  const nav = useNavigate();
  const documentsApi = useDocumentsApi();
  const referencesApi = useReferencesApi();
  const resumesApi = useResumesApi();

  const task = useAsync(async () => {
    const document = await documentsApi.get(documentId);
    if (document.referenceId === null) throw new Error('Document has no reference');
    const reference = await referencesApi.get(document.referenceId);
    const resume = await resumesApi.get(reference.resumeId);
    return {
      document,
      reference,
      resume,
    };
  });

  useEffect(() => {
    if (!task.value) return;
    if (task.value.document.deletedAt !== null) {
      nav(`/references/${task.value.document.referenceId}`);
    }
  }, [ document ]);

  return useMapping(() => {
    if (task.error) return <>{task.error.message}</>
    if (task.loading) return <></>
    return <Loaded
      resume={task.value.resume}
      reference={task.value.reference}
      document={task.value.document}
    />
  }, [ task.error, task.value ]);
}