import moment from 'moment';
import { useMapping } from "../../hooks";
import { Document, DocumentStatus } from "../../io/documents-api";
import { VerifiedReference } from "../../io/references-api";
import { useResume } from "../../io/resumes-api";
import { FontSize, Image, Text } from "../../ui";
import { Icon } from "../../ui/imagery/Icon";
import { Panel } from "../../ui/layout/Panel";
import { HorizontalBreak } from "../../ui/layout/horizontal-break";
import styles from './documents.module.scss';
import { Button } from '../../ui/interactive/button';
import { SummaryPanel } from '../resumes/summary-panel';
import { QuestionList } from '../lists/question-list';
import { useQuestions } from '../../io/questions-api';

export const ApprovedDocumentPanel = ({
  reference,
  document,
  onImageClicked,
}: {
  reference: VerifiedReference;
  document: Document,
  onImageClicked?: (src: string) => void;
}) => {
  const resume = useResume(document.resumeId);
  const questions = useQuestions({
    referenceId: reference.referenceId,
  });

  const verified = useMapping(() => {
    if (document.status !== DocumentStatus.Approved) {
      return <></>
    }

      // <div className={styles.cursive} style={{ color: 'black', textTransform: 'capitalize' }}>
    return <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '.25rem' }}>
      <Text capitalize fontSize={FontSize.Medium}>
        {reference.contactName}{reference.occupation ? `, ${reference.occupation}` : ''}
      </Text>
      <div style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}>
        <Icon style={{ color: '#03a9fc' }} icon='verified' />
        <Text fontSize={FontSize.Small} style={{ color: 'green', textDecoration: 'underline', textDecorationStyle: 'dotted', textUnderlineOffset: '4px' }}>
          Verified Reference
        </Text>
      </div>
      <Text fontSize={FontSize.Small}>{moment(reference.updatedAt).format('MM/DD/YYYY')}</Text>
      <QuestionList questions={(questions.value || []).filter(x => x.answer !== null)} />
    </div>
  }, [ document, questions.value ]);

  const skillButtons = useMapping(() => {
    return document.skills.map(skill => {
      return <Button className={styles.skillBox}>
        <Text capitalize center fontSize={FontSize.Small}>
          {skill}
        </Text>
      </Button>
    });
  }, [ document.skills ]);

  const imgSrc = `/api/v1/documents/${document.id}/file`;

  const portraitSection = <div
    style={{
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
    }}
  >
    <div style={{ width: '5rem', height: '5rem', borderRadius: '50%', overflow: 'hidden', marginLeft: 0, flexShrink: 0 }}>
      <Image objectFit='cover' src={`/api/v1/resumes/${document.resumeId}/image`} />
    </div>
    <Text capitalize fontSize={FontSize.Large}>{resume.value?.contactName}</Text>
  </div>

  const descriptionSection = <div>
    <Panel style={{ backgroundColor: 'lightblue', width: '100%' }}>
      <div style={{ margin: '1rem', }}>
        <SummaryPanel value={document.description} />
      </div>
    </Panel>
  </div>

  const skillSection = <div>
    <div style={{ width: '100%', display: 'flex' }}>
      {skillButtons}
    </div>
  </div>

  const verifiedSection = <div>
    {verified}
  </div>

  return <>
    <Panel style={{ maxWidth: '60rem', maxHeight: '80vh', overflow: 'scroll', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '1rem', flexBasis: 'calc(50% - 2rem)', minWidth: '20rem' }}>
        <Panel style={{ padding: '1rem' }} onClick={onImageClicked ? () => onImageClicked(imgSrc) : undefined}>
          <Image src={imgSrc} />
        </Panel>
      </div>
      <div style={{ padding: '1rem', display: 'flex', flexDirection: 'column', gap: '1rem', flexBasis: 'calc(50% - 2rem)', minWidth: '20rem' }}>
        <Panel style={{ display: 'flex', flexDirection: 'column', gap: '1rem', padding: '1rem', borderRadius: '7px' }}>
          {portraitSection}
          {descriptionSection}
          {skillSection}
        </Panel>
        {verifiedSection}
      </div>
    </Panel>
  </>
}