import { Link } from "react-router-dom";
import { useMapping } from "../../hooks";
import { Reference } from "../../io/references-api"
import styles from './lists.module.scss';
import { Panel } from "../../ui/layout/Panel";

const ReferenceItem = ({
  reference,
}: {
  reference: Reference;
}) => {
  return <Link to={`/references/${reference.id}`}>
    <Panel>
      <li key={`reference-${reference.id}`} className={styles.referenceItem}>
        <span className={styles.referenceName}>{reference.contactName || '<no-name>'}</span>
      </li>
    </Panel>
  </Link>
}

export const ReferenceList = ({
  references,
}: {
  references: Reference[];
}) => {
  const elems = useMapping(() => {
    return references.map(x => {
      return <ReferenceItem reference={x} />
    });
  }, [ references ]);

  return <ul className={styles.list}>
    {elems}
  </ul>
}